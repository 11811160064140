<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>

        <v-card-text class="text-center">
          <img
            src="@/assets/images/login_title.png"
            alt="비즈페이로고"
            style="margin-bottom:10px; padding:30px;"
          >
<!--
          <v-row
            align="center"
            justify="center"
          >
            <v-col cols="8" class="text-right">
                아이디 저장
            </v-col>

            <v-col cols="4">
              <v-btn-toggle
                v-model="saveId"
                mandatory
              >
                <v-btn value="on">
                  on
                </v-btn>
                <v-btn value="off">
                  off
                </v-btn>
              </v-btn-toggle>
            </v-col>

          </v-row>
          -->

          <v-text-field
            v-model="userId"
            label="대리점 ID"
          />

          <v-text-field
            v-model="loginPassWord"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            name="input-10-2"
            label="비밀번호"
            @click:append="show = !show"
            v-on:keyup.enter="login"
          />
          <v-row>
            <v-col cols="12">
              <div style="display: flex; align-items: center; justify-content: center ">
                <div style="align-items: center">
                  아이디 저장
                </div>
                <div>
                  <v-switch v-model="saveId">

                  </v-switch>
                </div>

                <div style="align-items: center; margin-left:30px;">
                  패스워드 저장
                </div>
                <div>
                  <v-switch v-model="savePwd">

                  </v-switch>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-btn
            class="mb-4"
            block
            color="success"
            dark
            @click="login"
          >
            로그인
          </v-btn>
          <div class="justify-around text-center flex-wrap">
            <v-btn
              text
              large
              color="#000000"
              href="tel://1600-0174"
            >
              <v-icon
                color="#000000"
                class="mx-auto"
              >
                mdi-phone
              </v-icon>
              고객센터 : 1600 - 0174
            </v-btn>
          </div>
        </v-card-text>
      </base-card>
    </div>
    <warning-one
      :dialog="warningDialog"
      :title="warningTitle"
      :content="warningContent"
      @dialogEvent="dialogEvent"
    />
  </div>
</template>
<script>
  import WarningOne from '@/components/dialog/WarningOne'
  import { mapActions } from 'vuex'
  import { deleteCookie, getCookie, setCookie } from '@/util/cookie.util'

  export default {
    name: 'Login',
    metaInfo: {
      title: '로그인',
    },
    components: {
      WarningOne,
    },
    data () {
      return {
        show: false,
        password: 'Password',
        warningDialog: false,
        warningTitle: '',
        warningContent: '',
        userId: '',
        loginPassWord: '',
        saveId: true,
        savePwd: false,
      }
    },
    mounted () {
      this.saveId = getCookie('saveIdCheck')
      this.savePwd = getCookie('savePwdCheck')

      if (this.saveId === 'true') {
        this.userId = getCookie('savedUserId')
        if (this.savePwd === 'true') {
          this.loginPassWord = getCookie('savedUserPwd')
          this.savePwd = true
        } else {
          this.loginPassWord = ''
          this.savePwd = false
        }
      } else {
        this.saveId = false
        this.savePwd = false
        this.userId = ''
        this.loginPassWord = ''
      }

    },
    methods: {
      ...mapActions(['dealerLogin']),
      dialogEvent (val) {
        this.warningDialog = val
      },
      login () {
        if (this.saveId) {
          setCookie('savedUserId', this.userId)

          if (this.savePwd) {
            setCookie('savedUserPwd', this.loginPassWord)
          } else {
            deleteCookie('savedUserPwd')
          }
        } else {
          deleteCookie('savedUserId')
          deleteCookie('savedUserPwd')
        }


        setCookie('saveIdCheck', this.saveId)
        setCookie('savePwdCheck', this.savePwd)


        this.dealerLogin({
          userId: this.userId,
          passWord: this.loginPassWord,
        }).then( _ =>{
            this.$router.push('/')
          }
        )
          .catch(error => {
          this.warningDialog = true
          this.warningTitle = '로그인에 실패했습니다.'
          this.warningContent = error
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #242939 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}

.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.v-input__control label {
  top: 0px;
}
</style>
