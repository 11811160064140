export const setCookie = (name, val) => {
  const date = new Date()
  const value = val

  date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000)

  if (typeof window !== 'undefined') document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/'
}

export const getCookie = (name) => {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')

  if (parts.length === 2) return parts.pop().split(';').shift()
}


export const deleteCookie = (name) => {
  const date = new Date()

  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000)

  if (typeof window !== 'undefined') document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/'
}
